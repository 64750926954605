import React from 'react'

import NotFoundError from 'containers/Error/NotFoundError'

export const Page404 = () => <NotFoundError />

export const getStaticProps = async () => ({
  props: { namespacesRequired: ['404'] },
})

export default Page404
